<template>
  <div class="layout">
    <HeadMenu />
    <router-view />
  </div>
</template>

<script>
export default {
  components: {
    HeadMenu: () => import('./components/Head-menu.vue')
  }
};
</script>

<style lang="less" scoped>
.layout{
  padding: 0 16px 16px;
  box-sizing: border-box;
}
</style>
